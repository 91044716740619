import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tricks-won-tracker',
  templateUrl: './tricks-won-tracker.component.html',
  styleUrls: ['./tricks-won-tracker.component.css']
})
export class TricksWonTrackerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
